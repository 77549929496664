<template>
  <div class="wrapper">
    <v-btn @click="clicked">
      <slot></slot>
    </v-btn>
  </div>
</template>

<script>
export default {
  methods: {
    clicked() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.v-btn {
  padding: 28px 30px !important;
  width: auto;
  background: white !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px !important;
  font-family: Ubuntu Mono, sans-serif;
  font-style: normal;
  font-weight: normal !important;
  font-size: 1.5em !important;
  text-align: center;
  color: black;
  user-select: none;
  text-transform: none !important;
}
/* 
    align-items: center;
    border-radius: 4
px
;
    display: inline-flex;
    flex: 0 0 auto;
    font-weight: 500;
    letter-spacing: 0.0892857143em;
    justify-content: center;
    outline: 0;
    position: relative;
    text-decoration: none;
    text-indent: 0.0892857143
em
;
    text-transform: uppercase;
    transition-duration: 0.28s;
    transition-property: box-shadow, transform, opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap; */
</style>