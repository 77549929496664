<template>
  <v-col
    align-self="center"
    class="d-flex flex-column justify-center align-center"
  >
    <header-box class="ma-6">{{ hello }}</header-box>
    <div v-if="!waiting">
      <word-button
        class="ma-2"
        v-for="(word, index) in words"
        :key="index"
        v-on:click="clicked(index)"
      >
        {{ word }}
      </word-button>
    </div>
  </v-col>
</template>

<script>
// import mutationTypes from "../store/mutation-types";
// import routes from "../router/routes";
import HeaderBox from "../components/HeaderBox.vue";
import WordButton from "../components/WordButton.vue";
import WORDS from "../words";

function getRandomSubarray(arr, size) {
  var shuffled = arr.slice(0),
    i = arr.length,
    temp,
    index;
  while (i--) {
    index = Math.floor((i + 1) * Math.random());
    temp = shuffled[index];
    shuffled[index] = shuffled[i];
    shuffled[i] = temp;
  }
  return shuffled.slice(0, size);
}

function randomWords() {
  return getRandomSubarray(WORDS, 5);
}

export default {
  components: { HeaderBox, WordButton },
  data: () => ({
    waiting: false,
    words: randomWords(),
  }),
  computed: {
    hello() {
      return this.waiting ? "Wait your turn" : "Choose a word";
    },
  },
  created() {
    // if (this.$store.state[mutationTypes.CODE]?.length != 4) {
    //   this.$router.push(routes.HOME);
    // }
  },
  methods: {
    clicked(i) {
      this.$set(this.words, i, getRandomSubarray(WORDS, 1)[0]);
    },
  },
};
</script>